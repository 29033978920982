import classNames from "classnames";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import { useServerSideProps } from "~/contexts/server-side-props";

import Button from "../common/button";
import LinkWrapper from "../common/link-wrapper";
import styles from "./preview-mode-bar.module.scss";

type Props = {};

export default function PreviewModeBar(props: Props) {
  const router = useRouter();
  const { serverSideProps } = useServerSideProps();
  const [insideIframe, setInsideIframe] = useState(true);
  const [hideBar, setHideBar] = useState(false);

  useEffect(() => {
    if (typeof window === "undefined" || window === window.parent) {
      setInsideIframe(false);
    }
  }, []);

  return (
    serverSideProps.draftMode && (
      <div className={classNames(styles.container, hideBar ? styles.hideBar : undefined)}>
        {insideIframe ? (
          <>
            <p>Preview mode is active, you&apos;re seeing the draft version of this page.</p>
            <Button variant="secondary" onClick={() => setHideBar(true)}>
              Hide Bar
            </Button>
          </>
        ) : (
          <>
            <p>Preview mode is active, but you&apos;re not currenty in Contentful.</p>
            <Button variant="secondary" onClick={() => setHideBar(true)}>
              Hide Bar
            </Button>
            <LinkWrapper
              lookAsButton="secondary"
              prefetch={false}
              href={`/api/next/contentful/disable-draft-mode?redirectTo=/${router.locale}/${router.asPath}`}
            >
              Exit preview mode
            </LinkWrapper>
          </>
        )}
      </div>
    )
  );
}
