import classNames from "classnames";
import { useTranslations } from "next-intl";

import { GlobalNotificationTypes } from "~/atoms/global-notification";
import { useGlobalNotification } from "~/hooks/use-global-notification";

import Button from "./button";
import styles from "./global-notification.module.scss";
import Icon from "./icon";
import LinkWrapper from "./link-wrapper";
import { PagePopup, PagePopupClose, PagePopupContent, PagePopupHeading } from "./page-popup";

export default function GlobalNotification() {
  const t = useTranslations();
  const { closeGlobalNotification, notificationState } = useGlobalNotification();

  const { open, slug, type, linkCaption, linkHref, ctaCaption, closeOnClickCta, onClickCta, title, description } =
    notificationState ?? {};

  if (slug === undefined) {
    return null;
  }

  const getGlobalNotificationIcon = () => {
    switch (type) {
      case GlobalNotificationTypes.error:
        return <Icon name="alert" aria-hidden width={16} height={16} />;
      case GlobalNotificationTypes.success:
        return <Icon name="checkmark" aria-hidden width={16} height={16} />;
      default:
      case GlobalNotificationTypes.info:
        return <Icon name="info" aria-hidden width={16} height={16} />;
    }
  };

  const getGlobalNotificationStyles = (): string => {
    switch (type) {
      case GlobalNotificationTypes.error:
        return classNames(styles.panel, styles.panelError);
      case GlobalNotificationTypes.success:
        return classNames(styles.panel, styles.panelSuccess);
      case GlobalNotificationTypes.info:
      default:
        return classNames(styles.panel, styles.panelInfo);
    }
  };

  const getGlobalNotificationTitle = () =>
    t.rich(title || `generic.globalnotification.title.${slug}`, {
      br: () => <br />,
      strong: (chunks) => <strong>{chunks}</strong>,
    });

  const getGlobalNotificationDescription = () =>
    t.rich(description || `generic.globalnotification.description.${slug}`, {
      br: () => <br />,
      strong: (chunks) => <strong>{chunks}</strong>,
    });

  if (Object.keys(notificationState as any).length === 0) {
    return <></>;
  }

  return (
    <PagePopup open={open}>
      <PagePopupContent dialogClassName={styles.pagePopup} panelClassName={getGlobalNotificationStyles()}>
        <div className={styles.GlobalNotificationContainer}>
          <PagePopupHeading className={styles.GlobalNotificationTitleContainer}>
            <div className={styles.GlobalNotificationIcon}>{getGlobalNotificationIcon()}</div>
            <div className={styles.GlobalNotificationTitle}>{getGlobalNotificationTitle()}</div>
            <PagePopupClose style={{ position: "static" }} onClick={() => closeGlobalNotification()}>
              <Icon name="close" width={16} height={16} />
            </PagePopupClose>
          </PagePopupHeading>
          <div className={styles.GlobalNotificationDescriptionContainer}>
            <div className={styles.GlobalNotificationDescription}>{getGlobalNotificationDescription()}</div>
          </div>
          <div className={styles.GlobalNotificationPromptContainer}>
            {linkHref && linkCaption ? (
              <LinkWrapper href={linkHref} className={classNames(styles.GlobalNotificationLink)}>
                {t(linkCaption)}
              </LinkWrapper>
            ) : null}
            {ctaCaption && (onClickCta || closeOnClickCta) ? (
              <Button
                variant="primary"
                className={classNames(styles.GlobalNotificationButton)}
                onClick={() => {
                  if (onClickCta) {
                    onClickCta();
                  }
                  if (closeOnClickCta) {
                    closeGlobalNotification();
                  }
                }}
              >
                {t(ctaCaption)}
              </Button>
            ) : null}
          </div>
        </div>
      </PagePopupContent>
    </PagePopup>
  );
}
