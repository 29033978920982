import { NextRouter } from "next/router";

import CrossCountry from "~/types/cross-country";

import countries from "../../public/json/countries.json";
import country2dialcode from "../../public/json/country2dialcode.json";
import objectUtils from "./object-utils";

/**
 * Utility functions for working with cross-country data.
 */
const crossCountryUtils = {
  /**
   * Gets the current locale from the Next.js router object with country uppercase.
   *
   * @param {NextRouter} router - The Next.js router object.
   * @returns {string} The current country code (defaults to "ae")
   */
  getCurrentLocaleWithCountryUppercase(router: NextRouter): string {
    const [language, country] = (router.locale ?? "en-ae").split("-");
    return country ? `${language}-${country.toUpperCase()}` : "en-AE";
  },

  /**
   * Gets the current country code from the Next.js router object.
   *
   * @param {NextRouter} router - The Next.js router object.
   * @returns {string} The current country code (defaults to "ae")
   */
  getCurrentCountryCode(router: NextRouter): string {
    return (router.locale?.split("-")[1] ?? "ae").toUpperCase();
  },

  /**
   * Gets the current language code from the Next.js router object.
   *
   * @param {NextRouter} router - The Next.js router object.
   * @returns {string} The current language code  (defaults to "en")
   */
  getCurrentLanguageCode(router: NextRouter): string {
    return router.locale?.split("-")[0] ?? "en";
  },

  /**
   * Filters the list of countries from the provided JSON and returns a new array
   * with all the countries ungrouped by continent.
   *
   * @param {object} json - The input JSON object containing continents and countries.
   * @returns {CrossCountry.CountryType[]} The filtered array with only country information.
   */
  getUngroupedCountryList(json: any): CrossCountry.CountryType[] {
    const filteredCountries: CrossCountry.CountryType[] = [];

    for (const continent of json?.continents) {
      for (const country of continent?.countries) {
        filteredCountries.push(country);
      }
    }

    return filteredCountries;
  },

  /**
   * Gets the cultures of the current country based on the country ISO code and the provided JSON data.
   *
   * @param {string} countryCode - ISO 3166 country code
   * @returns {CrossCountry.CultureType[]} The array of cultures of the current country, or undefined if not found.
   */
  getCurrentCountryCultures(countryCode: string): CrossCountry.CultureType[] {
    const filteredCountries: CrossCountry.CountryType[] = [];

    for (const continent of countries.continents) {
      for (const country of continent?.countries) {
        filteredCountries.push(country);
      }
    }

    return (
      filteredCountries!.find(
        (c: CrossCountry.CountryType) => c?.countryCode?.toLowerCase() === countryCode.toLowerCase()
      )?.cultures ?? []
    );
  },

  /**
   * Gets the list of language names in their own locale for the provided cultures.
   *
   * @param {string} countryCode - ISO 3166 country code
   * @param {string} languageCode - ISO 3166 language code
   * @param {CrossCountry.CultureType[]} cultures - The array of culture objects.
   * @returns {(CrossCountry.LanguageType | void)[]} The list of English language names.
   */
  getOwnLanguagesList(
    countryCode: string,
    languageCode: string,
    cultures: CrossCountry.CultureType[]
  ): (CrossCountry.LanguageType | void)[] {
    let languages = [];
    const defaultCultureCode = `${languageCode}-${countryCode.toLowerCase()}`;
    const defaultLanguageName = new Intl.DisplayNames(languageCode, { type: "language" });

    languages.push({
      name: defaultLanguageName.of(languageCode) ?? "",
      code: languageCode,
      cultureName: defaultLanguageName.of(defaultCultureCode) ?? "",
      cultureCode: defaultCultureCode,
    });

    for (const c of cultures) {
      const cultureCode = c.locale;
      const languageCode = cultureCode.split("-")[0];
      const languageLocalNames = new Intl.DisplayNames(languageCode, { type: "language" });
      const newItem = {
        name: languageLocalNames.of(languageCode) ?? "",
        code: languageCode,
        cultureName: languageLocalNames.of(cultureCode) ?? "",
        cultureCode: cultureCode,
      };

      if (languages.find((el) => el.cultureCode == newItem.cultureCode)) {
        continue;
      }

      languages.push({
        name: languageLocalNames.of(languageCode) ?? "",
        code: languageCode,
        cultureName: languageLocalNames.of(cultureCode) ?? "",
        cultureCode: cultureCode,
      });
    }

    return languages;
  },

  /**
   * Gets the list of English language names for the provided cultures.
   *
   * @param {NextRouter} router - The Next.js router object.
   * @param {CrossCountry.CultureType[]} cultures - The array of culture objects.
   * @returns {(CrossCountry.LanguageType | void)[]} The list of English language names.
   */
  getEnglishLanguagesList(
    router: NextRouter,
    cultures: CrossCountry.CultureType[]
  ): (CrossCountry.LanguageType | void)[] {
    const languages = [];
    const defaultLanguageCode = this.getCurrentLanguageCode(router);
    const defaultCountryCode = this.getCurrentLanguageCode(router);
    const defaultCultureCode = `${defaultLanguageCode}-${defaultCountryCode.toUpperCase()}`;
    const defaultLanguageName = new Intl.DisplayNames("en", { type: "language" });

    languages.push({
      name: defaultLanguageName.of(defaultLanguageCode) ?? "",
      code: defaultLanguageCode,
      cultureName: defaultLanguageName.of(router.locale ?? defaultCultureCode) ?? "",
      cultureCode: defaultCultureCode,
    });

    const languageLocalNames = new Intl.DisplayNames("en", { type: "language" });

    for (const c of cultures) {
      const cultureCode = c.locale;
      const languageCode = cultureCode.split("-")[0];
      const newItem = {
        name: languageLocalNames.of(languageCode) ?? "",
        code: languageCode,
        cultureName: languageLocalNames.of(cultureCode) ?? "",
        cultureCode: cultureCode,
      };
      const languagesLastItem = languages[languages.length - 1];

      if (objectUtils.deepEqual(languagesLastItem, newItem)) {
        continue;
      }

      languages.push({
        name: languageLocalNames.of(languageCode) ?? "",
        code: languageCode,
        cultureName: languageLocalNames.of(cultureCode) ?? "",
        cultureCode: cultureCode,
      });
    }

    return languages;
  },

  /**
   * Gets the list of localized language names for the provided cultures.
   *
   * @param {string} locale - The locale string for localization.
   * @param {NextRouter} router - The Next.js router object.
   * @param {CrossCountry.CultureType[]} cultures - The array of culture objects.
   * @returns {(CrossCountry.LanguageType | void)[]} The list of localized language names.
   */
  getLocalLanguagesList(
    locale: string,
    router: NextRouter,
    cultures: CrossCountry.CultureType[]
  ): (CrossCountry.LanguageType | void)[] {
    const languages = [];
    const defaultLanguageCode = this.getCurrentLanguageCode(router);
    const defaultCountryCode = this.getCurrentLanguageCode(router);
    const defaultCultureCode = `${defaultLanguageCode}-${defaultCountryCode.toUpperCase()}`;
    const defaultLanguageName = new Intl.DisplayNames(locale, { type: "language" });

    languages.push({
      name: defaultLanguageName.of(defaultLanguageCode) ?? "",
      code: defaultLanguageCode,
      cultureName: defaultLanguageName.of(router.locale ?? defaultCultureCode) ?? "",
      cultureCode: defaultCultureCode,
    });

    const languageLocalNames = new Intl.DisplayNames(locale, { type: "language" });

    for (const c of cultures) {
      const cultureCode = c.locale;
      const languageCode = cultureCode.split("-")[0];
      const newItem = {
        name: languageLocalNames.of(languageCode) ?? "",
        code: languageCode,
        cultureName: languageLocalNames.of(cultureCode) ?? "",
        cultureCode: cultureCode,
      };
      const languagesLastItem = languages[languages.length - 1];

      if (objectUtils.deepEqual(languagesLastItem, newItem)) {
        continue;
      }

      languages.push({
        name: languageLocalNames.of(languageCode) ?? "",
        code: languageCode,
        cultureName: languageLocalNames.of(cultureCode) ?? "",
        cultureCode: cultureCode,
      });
    }

    return languages;
  },

  /**
   * Finds the country with the provided phone number prefix or undefined.
   *
   * @param prefix - The phone number prefix
   */
  getCountryFromPhonePrefix(prefix: string, routerCountry?: string) {
    if (routerCountry && prefix === country2dialcode[routerCountry as keyof typeof country2dialcode]) {
      return routerCountry;
    }

    return Object.entries(country2dialcode).find(([_, p]) => prefix === p)?.[0];
  },

  /**
   * Finds the phone number prefix for the provided country or undefined.
   *
   * @param country - The country
   */
  getPhonePrefixFromCountry(country: string) {
    return country2dialcode[country?.toLowerCase() as keyof typeof country2dialcode] || undefined;
  },

  /**
   * Used to convert in the localized country name a country ISO code
   *
   * @param {string} country - country code
   * @param {NextRouter} router - The Next.js router object.
   * @returns {string} localized country name
   */
  getLocalizedCountryName(country: string, router: NextRouter): string {
    let language = this.getCurrentLanguageCode(router);
    const regionNames = new Intl.DisplayNames([language], { type: "region" });
    return regionNames.of(country.toUpperCase())!;
  },

  /**
   * Retrieves a list of available user languages based on the current country and language.
   * Used mainly in sign up and edit profile.
   *
   * @param {NextRouter} router - The Next.js router object.
   * @returns {Array<{ label: string, value: string }>} An array of available user languages with labels and values.
   */
  getAvailableUserLanguages(router: NextRouter): Array<{ label: string; value: string }> {
    const initialCultures = this.getCurrentCountryCultures(this.getCurrentCountryCode(router));
    return this.getOwnLanguagesList(
      this.getCurrentCountryCode(router),
      this.getCurrentLanguageCode(router),
      initialCultures
    ).map((l) => ({
      label: l?.name ?? "",
      value: l?.code ?? "",
    }));
  },
};

export default crossCountryUtils;
