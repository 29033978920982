import {
  FloatingFocusManager,
  FloatingPortal,
  safePolygon,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useId,
  useInteractions,
  useMergeRefs,
  useRole,
} from "@floating-ui/react";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslations } from "next-intl";
import {
  ButtonHTMLAttributes,
  cloneElement,
  createContext,
  CSSProperties,
  Dispatch,
  forwardRef,
  HTMLProps,
  isValidElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useRecoilState } from "recoil";

import { openedLabelIdState } from "~/atoms/page-popup";
import { useBreakpoint } from "~/contexts/breakpoint";
import { basicAnimationStates } from "~/utils/animation-utils";
import Constants from "~/utils/constants";
import useScrollDirection from "~/utils/use-scroll-direction";

import Container from "./container";
import Grid from "./grid";
import styles from "./page-popup.module.scss";
import Pane from "./pane";

type PagePopupOptions = {
  initialOpen?: boolean;
  opensOn?: "hover" | "click";
  opensOnSR?: boolean;
  closesOn?: "hover" | "click";
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  onOpen?: () => void;
  onClose?: () => void;
  restMs?: number;
  autoCloseAfterMs?: number;
};

export function usePagePopup({
  initialOpen = false,
  open: controlledOpen,
  opensOn = "hover",
  opensOnSR,
  closesOn = "hover",
  onOpenChange: setControlledOpen,
  onOpen,
  onClose,
  restMs = 150,
  autoCloseAfterMs = 0,
}: PagePopupOptions = {}) {
  const pagePopupId = useId();
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
  const [labelId, setLabelId] = useState<string | undefined>();
  const [descriptionId, setDescriptionId] = useState<string | undefined>();

  // Recoil state that holds the id of the currently opened pagePopup (undefined if none)
  const [openedLabelId, setOpenedLabelId] = useRecoilState(openedLabelIdState);

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = useCallback((open: boolean) => {
    setControlledOpen ? setControlledOpen(open) : setUncontrolledOpen(open);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.info(`[${pagePopupId}] set Open popup to ${open}`);
    if (open) {
      setOpenedLabelId(pagePopupId);
    } else {
      setOpenedLabelId(undefined);
    }
  }, [open, pagePopupId, setOpenedLabelId]);

  // if openedLabelId is different to this pagePopupId, close it
  useEffect(() => {
    if (openedLabelId != undefined && openedLabelId !== pagePopupId) {
      // console.info(`[${pagePopupId}] close current popup`);
      setOpen(false);
    }
  }, [openedLabelId, pagePopupId, setOpen]);

  // if autoCloseAfterMs has a valid value, auto-close the popup after a specific time
  useEffect(() => {
    if (autoCloseAfterMs > 0) {
      const autoCloseTimer = setTimeout(() => {
        setOpen(false);
      }, autoCloseAfterMs);
      return () => clearInterval(autoCloseTimer);
    }
  }, [autoCloseAfterMs, setOpen]);

  useEffect(() => {
    if (open && onOpen) {
      onOpen();
    } else if (!open && onClose) {
      onClose();
    }
  }, [open, onOpen, onClose]);

  const data = useFloating({
    open,
    onOpenChange: setOpen,
  });

  const context = data.context;

  const click = useClick(context, {
    enabled: (opensOn === "click" && open === false) || (closesOn === "click" && open === true) || opensOnSR === true,
  });

  const hover = useHover(context, {
    enabled: (opensOn === "hover" && open === false) || (closesOn === "hover" && open === true),
    handleClose: safePolygon(),
    restMs,
  });

  const dismiss = useDismiss(context, {
    enabled: closesOn === "click" && open === true,
    outsidePressEvent: "mousedown",
  });

  const role = useRole(context);

  const interactions = useInteractions([click, hover, dismiss, role]);

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
    }),
    [open, setOpen, interactions, data, labelId, descriptionId]
  );
}

type ContextType =
  | (ReturnType<typeof usePagePopup> & {
      setLabelId: Dispatch<SetStateAction<string | undefined>>;
      setDescriptionId: Dispatch<SetStateAction<string | undefined>>;
    })
  | null;

const PagePopupContext = createContext<ContextType>(null);

export const usePagePopupContext = () => {
  const context = useContext(PagePopupContext);

  if (context == null) {
    throw new Error("PagePopup components must be wrapped in <PagePopup />");
  }

  return context;
};

// PagePopup component
export function PagePopup({
  children,
  ...options
}: {
  children: ReactNode;
} & PagePopupOptions) {
  const pagepopup = usePagePopup(options);
  return <PagePopupContext.Provider value={pagepopup}>{children}</PagePopupContext.Provider>;
}

type PagePopupTriggerProps = {
  children: ReactNode;
  asChild?: boolean;
  style?: CSSProperties;
};

// PagePopupTrigger component
export const PagePopupTrigger = forwardRef<HTMLElement, HTMLProps<HTMLElement> & PagePopupTriggerProps>(
  function PagePopupTrigger({ children, asChild = false, style: customStyle, ...props }, propRef) {
    const context = usePagePopupContext();
    const childrenRef = (children as any).ref;
    const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

    // `asChild` allows the user to pass any element as the anchor
    if (asChild && isValidElement(children)) {
      return cloneElement(
        children,
        context.getReferenceProps({
          ref,
          ...props,
          ...children.props,
          "data-state": context.open ? "open" : "closed",
        })
      );
    }

    return (
      <button
        ref={ref}
        // The user can style the trigger based on the state
        data-state={context.open ? "open" : "closed"}
        {...context.getReferenceProps(props)}
        style={customStyle}
      >
        {children}
      </button>
    );
  }
);

type PagePopupContentProps = {
  children: ReactNode;
  asChild?: boolean;
  dialogClassName?: string;
  panelClassName?: string;
  paneStyle?: CSSProperties;
};

// PagePopupContent component
export const PagePopupContent = forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement> & PagePopupContentProps>(
  function PagePopupContent(
    { children, dialogClassName, panelClassName, paneStyle: customPaneStyle, ...props },
    propRef
  ) {
    const { context: floatingContext, ...context } = usePagePopupContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    const breakpoint = useBreakpoint();
    const { dir, y } = useScrollDirection({
      initialDirection: "up",
      thresholdPixels: breakpoint == "desktop" ? Constants.HEADER_HEIGHT_DESKTOP : Constants.HEADER_HEIGHT_MOBILE,
    });

    // if (!floatingContext.open) return null;

    return (
      <FloatingPortal>
        <FloatingFocusManager context={floatingContext}>
          <AnimatePresence>
            {floatingContext.open && (
              <motion.div
                ref={ref}
                initial={basicAnimationStates.off}
                animate={basicAnimationStates.on}
                exit={basicAnimationStates.off}
                aria-labelledby={context.labelId}
                aria-describedby={context.descriptionId}
                role="dialog"
                aria-modal="true"
                aria-live="polite"
                className={classNames(
                  styles.pagePopup,
                  dialogClassName,
                  { [styles.scrolledTotal]: dir === "down" },
                  { [styles.scrolledTop]: y >= Constants.HEADER_HEIGHT_DESKTOP }
                )}
                {...context.getFloatingProps(props)}
              >
                <Container>
                  <Grid>
                    <Pane className={classNames(styles.panel, panelClassName)} style={customPaneStyle}>
                      {children}
                    </Pane>
                  </Grid>
                </Container>
              </motion.div>
            )}
          </AnimatePresence>
        </FloatingFocusManager>
      </FloatingPortal>
    );
  }
);

export type CustomPagePoupHeadingStyles = {
  PagePopupHeading?: string[];
};

type PagePopupHeadingProps = {
  className?: CustomPagePoupHeadingStyles;
  style?: CSSProperties;
};

// PagePopupHeading component
export const PagePopupHeading = forwardRef<HTMLHeadingElement, HTMLProps<HTMLHeadingElement> & PagePopupHeadingProps>(
  function PagePopupHeading({ children, className, style: customStyle, ...props }, propRef) {
    const { setLabelId } = usePagePopupContext();
    const id = useId();

    // Only sets `aria-labelledby` on the PagePopup root element
    // if this component is mounted inside it.
    useLayoutEffect(() => {
      setLabelId(id);
      return () => setLabelId(undefined);
    }, [id, setLabelId]);

    return (
      <h2
        {...props}
        ref={propRef}
        id={id}
        style={customStyle}
        aria-labelledby={id}
        className={classNames(styles.heading, className)}
      >
        {children}
      </h2>
    );
  }
);

type PagePopupDescriptionProps = {
  style?: CSSProperties;
};

// PagePopupDescription component
export const PagePopupDescription = forwardRef<
  HTMLParagraphElement,
  HTMLProps<HTMLParagraphElement> & PagePopupDescriptionProps
>(function PagePopupDescription({ children, style: customStyle, ...props }, propRef) {
  const { setDescriptionId } = usePagePopupContext();
  const id = useId();

  // Only sets `aria-describedby` on the PagePopup root element
  // if this component is mounted inside it.
  useLayoutEffect(() => {
    setDescriptionId(id);
    return () => setDescriptionId(undefined);
  }, [id, setDescriptionId]);

  return (
    <p {...props} ref={propRef} id={id} style={customStyle} aria-describedby={id}>
      {children}
    </p>
  );
});

type PagePopupCloseProps = {
  style?: CSSProperties;
  onClick?: () => void;
};

// PagePopupClose component (close button)
export const PagePopupClose = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement> & PagePopupCloseProps
>(function PagePopupClose({ children, onClick, style: customStyle, ...props }, propRef) {
  const { setOpen } = usePagePopupContext();
  const t = useTranslations();

  return (
    <button
      type="button"
      aria-label={t("generic.close")}
      {...props}
      ref={propRef}
      onClick={() => {
        if (onClick) {
          onClick();
        }
        setOpen(false);
      }}
      className={styles.closeButton}
      style={customStyle}
    >
      {children}
    </button>
  );
});
