/**
 * Verifies if a specific type of storage is available on the current browser.
 *
 * @param storageType - The storage to check
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#testing_for_availability
 */
export function storageAvailable(storageType: "localStorage" | "sessionStorage") {
  if (typeof window === "undefined") {
    return false;
  }

  let storage;
  try {
    storage = window[storageType];
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      e.name === "QuotaExceededError" &&
      // acknowledge QuotaExceededError only if there's something already stored
      !!storage &&
      storage.length !== 0
    );
  }
}

/**
 * Checks if the storage type is available and uses it in the given callback.
 *
 * @param storageType - The storage to use
 * @param cb - Callback to call with the existing storage instance
 */
export function withStorageAvailable(storageType: "localStorage" | "sessionStorage", cb: (storage: Storage) => void) {
  if (storageAvailable(storageType)) {
    cb(window[storageType]);
  }
}

/**
 * Checks if both storage types are available and uses them in the given callback.
 *
 * @param cb - Callback to call with the existing storage instances
 */
export function withStoragesAvailable(cb: (localStorage: Storage, sessionStorage: Storage) => void) {
  if (storageAvailable("localStorage") && storageAvailable("sessionStorage")) {
    cb(window.localStorage, window.sessionStorage);
  }
}
