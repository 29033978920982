import { HttpStatusCode } from "axios";
import { plainToInstance } from "class-transformer";

import { ErrorResponse } from "~/shared/api/error-response.class";
import { RestVerbs } from "~/shared/api/rest-verbs.enum";
import CommonUtils from "~/utils/common-utils";

type DynamicFetchReturn<T, U> = T extends "blob"
  ? Blob
  : T extends "arraybuffer"
  ? ArrayBuffer
  : T extends "text"
  ? string
  : T extends "json"
  ? U
  : T;

export interface HttpConfig<T = any> {
  cacheKey?: string;
  clearCache?: boolean;
  url: string;
  method?: RestVerbs | string;
  useCredentials?: boolean;
  body?: T;
  headers?: Record<string, string>;
  queryParams?: Record<string, string | number | symbol>;
  transform?: boolean;
  responseType?: "blob" | "arraybuffer" | "text" | "json" | "jsonp";
  // Maybe these can be handy in the future
  // preprocessError?: <U>(r: HttpException<U>) => any;
  // preprocessResponse?: (r: T) => any;
}

export interface HttpException<T = any> {
  status: HttpStatusCode;
  message: string;
  body: T;
}

/**
 * @deprecated use the one from @kikocosmeticsorg/common-fe instead!
 */
export class HttpService {
  private static _config = {
    cachePrefix: "kik_rest_cache_",
    mappedUrls: void 0,
    shared: new Map(),
  };

  static $http(config: HttpConfig): Promise<DynamicFetchReturn<HttpConfig["responseType"], any>>;
  static $http<T = any>(config: HttpConfig): Promise<T>;
  static $http(config: HttpConfig): Promise<DynamicFetchReturn<HttpConfig["responseType"], Blob>>;
  static $http(config: HttpConfig): Promise<DynamicFetchReturn<HttpConfig["responseType"], ArrayBuffer>>;
  static $http(config: HttpConfig): Promise<DynamicFetchReturn<HttpConfig["responseType"], string>>;
  static $http<T, U, V>(config: HttpConfig): Promise<DynamicFetchReturn<HttpConfig["responseType"], U>>;
  static $http<T extends object | Blob | ArrayBuffer, U, V>(
    config: HttpConfig<T>
  ): Promise<DynamicFetchReturn<HttpConfig["responseType"], U>> {
    return this._generateAjax<T, U, V>(config);
  }

  // private

  static _cacheAdd(key: string, response: unknown): void {
    sessionStorage.setItem(`${this._config.cachePrefix}${key}`, JSON.stringify(response));
  }

  static _cacheFlush() {
    Object.keys(sessionStorage)
      .filter((k) => k.startsWith(this._config.cachePrefix))
      .forEach(this._cacheRemove);
  }

  static _cachePop<T = unknown>(key: string): T | void {
    const value = this._cacheRead<T>(key);
    this._cacheRemove(key);

    return value;
  }

  static _cacheRead<T = unknown>(key: string): T | void {
    const rawResponse = sessionStorage.getItem(`${this._config.cachePrefix}${key}`);
    try {
      const parsed = JSON.parse(rawResponse!);
      if (!!parsed) {
        return parsed;
      }
    } catch (e) {
      console.warn(`HttpService: error while reading cache for ${key}`, rawResponse);
      this._cacheRemove(key); // Clear up to avoid further errors
    }
  }

  static _cacheRemove(key: string): void {
    sessionStorage.removeItem(`${this._config.cachePrefix}${key}`);
  }

  static _generateAjax<T, U, V>(config: HttpConfig<T>): Promise<DynamicFetchReturn<typeof config.responseType, U>> {
    CommonUtils.assertIsNotEmpty(config);
    const isGetRequest = config.method === RestVerbs.GET || config.method === void 0;
    if (CommonUtils.isString(config.cacheKey)) {
      config.clearCache && this._cacheRemove(config.cacheKey);
      const cached = this._cacheRead<U>(config.cacheKey);

      return cached
        ? Promise.resolve(cached)
        : this._generateAjaxBase<T, U, V>(config).then((response) => {
            this._cacheAdd(config.cacheKey!, response);

            return response;
          });
    }
    const shareableKey = config.url;
    if (this._config.shared.has(shareableKey)) {
      return this._config.shared.get(shareableKey);
    }
    const call$ = this._generateAjaxBase<T, U, V>(config);
    // ONLY GET REQUESTS CAN BE SHARED BECAUSE THE BODY COULD CHANGE IN PATCH/POST/PUT/DELETE
    if (isGetRequest) {
      this._config.shared.set(
        shareableKey,
        call$.finally(() => this._config.shared.delete(shareableKey))
      );
    }

    return call$;
  }

  private static _generateAjaxBase<T, U, V>({
    url,
    method,
    useCredentials = false,
    body,
    headers = {},
    queryParams = {},
    responseType,
  }: HttpConfig<T>): Promise<DynamicFetchReturn<typeof responseType, U>> {
    const opts: RequestInit = {
      method: method,
      headers: new Headers({
        "content-type": "application/json", // by default setting the content-type to be json type
        "x-locale": `${location.href}`.match(/\/([a-zA-Z]{2}-[a-zA-Z]{2})\//)?.[1] || "ND", // the current locale
        ...headers,
      }),
      body: body ? JSON.stringify(body) : null,
    };
    if (useCredentials || url.includes(process.env.NEXT_PUBLIC_API_V2_BASE_URL!)) {
      opts.credentials = "include";
    }
    if (queryParams) {
      const tmpUrl = new URL(url, location.origin);
      tmpUrl.search = new URLSearchParams({
        ...tmpUrl.searchParams,
        ...queryParams,
      }).toString();
      url = tmpUrl.href;
    }

    return fetch(url, opts).then((res: Response): Promise<DynamicFetchReturn<typeof responseType, U>> => {
      if (res.ok) {
        switch (responseType) {
          case "blob":
            return res.blob();
          case "arraybuffer":
            return res.arrayBuffer();
          case "text":
            return res.text();
          case "json":
          default:
            return res.json() as Promise<U>;
        }
      }

      return res.json().then((json: V) => {
        let rejectValue: V | ErrorResponse = json;
        // to be able to access error status when you catch the error
        try {
          rejectValue = plainToInstance(ErrorResponse, json);
        } catch (e) {
          console.debug("http: failed to parse error response into ErrorResponse", e);
        }

        return Promise.reject({
          status: res.status,
          message: res.statusText,
          body: rejectValue,
        } as HttpException);
      });
    });
  }
}
