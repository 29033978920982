const appRoutes = {
  HOMEPAGE: "/",
  LOGIN: "/login",
  LOGIN_SIGNUP: "/login-sign-up/",
  LOYALTY: "/my-account/loyalty",
  MYACCOUNT: "/my-account",
  MYACCOUNT_PURCHASES: "/my-account/purchases",
  MYACCOUNT_PURCHASE_DETAIL: "/order-detail",
  MYACCOUNT_LEGACY_PURCHASES: "/my-account/legacy-purchases",
  MYACCOUNT_WISHLIST: "/my-account/wishlist",
  MYACCOUNT_SETTINGS: "/my-account/settings",
  POWERREVIEWS_WRITE_A_REVIEW: "/write-a-review",
  // [TS]: Changing these urls to be relative.
  PASSWORD_RESET: "password-reset",
  PASSWORD_RESET_COMPLETE: "password-reset-complete",
  PASSWORD_RESET_CONFIRMATION: "password-reset-request-sent",
  PASSWORD_RESET_REQUEST: "password-reset-request",
  SIGN_UP: "sign-up",
  //
  FAQ_HELP: "/faq-help",
  STORE_LOCATOR: "https://kiko-stores.kikocosmetics.com",
  CUSTOMER_HELP_CENTER: "/customer-help-center",
  REQUEST_RETURN: "/request-return",
  CART: "/cart",
  CHECKOUT: "/checkout",
  THANKYOU: "thankyou",
  TRACK_YOUR_ORDER: "/track-your-order/",
  ACCESSIBILITY_STATEMENT: "secure-purchases/accessibility-statement",
  COOKIE_POLICY: "help/cookie-policy",
  PRIVACY_POLICY: "help/privacy-policy",
};
export default appRoutes;
