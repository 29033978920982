import { useRouter } from "next/router";
import Script from "next/script";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { sequentialLoadingState, usableNetReadyState } from "~/atoms/sequential-loading";
import { useTextDir } from "~/contexts/text-dir";
import { ApiUtils } from "~/shared/api/api-utils.class";
import { UsablenetConfiguration } from "~/types/usablenet";
import CommonUtils from "~/utils/common-utils";
import crossCountryUtils from "~/utils/cross-country-utils";

export default function UsableNetIntegration() {
  const usablenetConfig = CommonUtils.parseJsonSafely(
    process.env.NEXT_PUBLIC_USABLENET_CONFIG!
  ) as UsablenetConfiguration;

  const dir = useTextDir();
  const router = useRouter();
  const language = crossCountryUtils.getCurrentLanguageCode(router);
  const sequentialLoadingReady = useRecoilValue(usableNetReadyState);
  // Recoil state that tells if the Usablenet is loaded
  const setUsablenetLoaded = useSetRecoilState(sequentialLoadingState);

  return usablenetConfig?.enabled && sequentialLoadingReady ? (
    <Script
      id="usntA40start"
      src={ApiUtils.interpolateParams(usablenetConfig.scripts.a11yToggle, { lang: language })}
      data-color="dark"
      data-position={dir === "rtl" ? "bottom-right" : "bottom-left"}
      type="text/javascript"
      async
      onReady={() => setUsablenetLoaded((current) => ({ ...current, usablenet: true }))}
    />
  ) : null;
}
