import { NextRouter } from "next/router";
import { NumberFormatOptions, useFormatter } from "next-intl";
import { DateTime, Info } from "ts-luxon";

import { useTextDir } from "~/contexts/text-dir";

import localeConfig from "../../locale.config";
import crossCountryUtils from "./cross-country-utils";

/**
 * A utility module for internationalization.
 * @module intlUtils
 */
const intlUtils = {
  defaultDatepickerFormat: "yyyy-MM-dd",
  defaultDateFormat: "dd/MM/yyyy",
  defaultDayFormat: "ccc",
  defaultMonthFormat: "LLL",
  defaultYearFormat: "yyyy",
  /**
   * Returns a formatter for currency values.
   * @function usePriceFormatter
   * @returns {Object} An object with a `currency` method.
   * @property {Function} currency - Formats a number as a currency string.
   * @returns {string} The formatted currency string.
   */
  usePriceFormatter() {
    const dir = useTextDir();
    const formatter = useFormatter();

    return {
      currency: (value: number, options: NumberFormatOptions) => {
        let formattedValue = "";

        if (dir === "rtl") {
          formattedValue = `${options.currency} ${formatter.number(value, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            numberingSystem: "latn",
          })}`;
        } else {
          formattedValue = formatter.number(value, { ...options, style: "currency" });
        }

        // Replace "NaN" with "—" (in case value is not a number, like when there are erroneous products wihtout display_price (UE-467))
        return formattedValue.replace("NaN", "—");
      },
    };
  },

  /**
   * Returns an array of years between two dates.
   * @function getYears
   * @param {DateTime | Date} minimumDate - The start date.
   * @param {DateTime | Date} maximumDate - The end date.
   * @returns {string[]} An array of years.
   */
  getYears(minimumDate: DateTime | Date, maximumDate: DateTime | Date): string[] {
    const minDate = (minimumDate instanceof Date ? DateTime.fromJSDate(minimumDate) : minimumDate) as DateTime;
    const maxDate = (maximumDate instanceof Date ? DateTime.fromJSDate(maximumDate) : maximumDate) as DateTime;

    return Array.from({ length: maxDate.year - minDate.year }).map((_item_, index) => `${maxDate.year - index}`);
  },

  /**
   * Returns an array of localized month names.
   * @function getMonths
   * @param {NextRouter} router - The Next.js router object.
   * @returns {string[]} An array of month names in the current locale.
   */
  getMonths(router: NextRouter) {
    return Info.months("long", { locale: crossCountryUtils.getCurrentLocaleWithCountryUppercase(router) });
  },

  /**
   * Returns an array of localized day names.
   * @function getDays
   * @param {NextRouter} router - The Next.js router object.
   * @returns {string[]} An array of day names in the current locale.
   */
  getDays(router: NextRouter) {
    return Info.weekdays("long", { locale: crossCountryUtils.getCurrentLocaleWithCountryUppercase(router) });
  },

  /**
   * Returns locale supported by next-intl to avoid hydration errors on safari.
   * @function getSupportedLocale
   * @param {NextRouter} router - The Next.js router object.
   * @returns {string} locale.
   */
  getSupportedLocale(router: NextRouter) {
    const nextIntlUnsupportedLocales = localeConfig.multiCountry.reduce(
      (acc: any, nextLocale) => {
        acc[nextLocale] = "en-gb";

        return acc;
      },
      { "fr-ch": "it-ch" }
    );

    return nextIntlUnsupportedLocales[router.locale as keyof typeof nextIntlUnsupportedLocales] ?? router.locale;
  },
};

export default intlUtils;
