import CrossCountry from "~/types/cross-country";
import Editorials from "~/types/editorials";

import bitmaskUtils from "./bitmask-utils";
import Constants from "./constants";
import invariant from "./invariant";

const productUtils = {
  partitionChildrenProductsByParent(products: CrossCountry.ChildrenProduct[]) {
    const output: Record<string, CrossCountry.Product[]> = {};

    for (const product of products) {
      invariant(
        product.parent_product_ids && product.parent_product_ids.length > 0,
        `Found child product without parents`
      );
      const parentId = product.parent_product_ids[0];

      if (output[parentId.toString()]) {
        output[parentId.toString()].push(product);
      } else {
        output[parentId.toString()] = [product];
      }
    }

    return output;
  },

  getFirstAvailableProductOtherwiseGetFirstOOS(
    product: CrossCountry.Product,
    hasChildren?: boolean,
    childrenIndex?: number
  ) {
    if (!hasChildren) {
      return product;
    }

    if (childrenIndex !== undefined && product.children![childrenIndex].has_stock) {
      return product.children![childrenIndex];
    }

    if (!product.has_stock) {
      return product.children![0];
    }

    return product.children!.find((c) => c.has_stock) ?? product.children![0];
  },

  // TODO understand the proper status mapping
  getOrderStatus(order: EVA.Core.OrderDto) {
    if (order.TotalItems == 0) {
      return "generic.order_status.cancelled";
    }
    // TODO to be updated after TESI integration
    // if (order.IsDelivered) {
    //   return "generic.order_status.delivered";
    // }
    if (order.IsShipped || order.IsDelivered) {
      return "generic.order_status.shipped";
    }
    if (order.IsPlaced) {
      return "generic.order_status.preparation";
    }
    return "generic.order_status.confirmed";
  },

  getOrderStatusCode(order: EVA.Core.OrderDto) {
    // if (order.IsDelivered) {
    //   return 3;
    // }
    if (order.IsShipped || order.IsDelivered) {
      return 2;
    }
    if (order.IsPlaced) {
      return 1;
    }
    return 0;
  },

  getOrderType(order: EVA.Core.OrderDto) {
    if (order.OrderCustomType && Constants.ECOMMERCE_ORDER_TYPES.includes(order.OrderCustomType.BackendID)) {
      return "generic.online_order";
    }
    if (order.PickupOrganizationUnitID != order.SoldFromOrganizationUnitID) {
      return "generic.instore_reservation";
    }
    return "generic.instore_purchase";
  },

  hasProductType(product: unknown, type: EVA.Core.ProductTypes) {
    if (!product || typeof product !== "object") {
      // Product does not have the correct shape
      return false;
    }

    if ("product_type" in product && typeof product.product_type === "number") {
      // Check the product type through a bitmask test.
      return bitmaskUtils.test(product.product_type, type);
    }

    if ("product_types" in product && Array.isArray(product.product_types)) {
      // Check the product type through its array.
      return product.product_types.includes(type);
    }

    // Product does not have the correct field
    return false;
  },

  isVirtualProduct(product: unknown) {
    return this.hasProductType(product, Constants.PRODUCT_TYPES.VirtualProduct);
  },

  isGiftCard(product: unknown) {
    return this.hasProductType(product, Constants.PRODUCT_TYPES.GiftCard);
  },

  getProductParentId(product: { logical_level_hierarchy: { name: string; product_id: string }[] }) {
    if (!product.logical_level_hierarchy) {
      return null;
    }

    return product.logical_level_hierarchy.find((e: { name: string }) => e.name === "root")?.product_id ?? null;
  },

  getProductDroplet(media?: CrossCountry.Media[]) {
    return media?.find((media) => Constants.PRODUCT_MEDIA_DROPLET_NAMES.includes(media.name));
  },

  getEditorialTabs(product: CrossCountry.DetailedProduct, environmentLabels?: string | Record<string, string>) {
    const output: { key: string; content: string | Record<string, string> }[] = [];
    const keys = (product.editorial_content_show_order ?? []).concat("ingredient_list");

    for (const key of keys) {
      if (key in product) {
        output.push({ key, content: product[key as keyof CrossCountry.DetailedProduct] as string });
      }
    }
    if (environmentLabels) {
      output.push({ key: Constants.ENVIRONMENT_LABELS_KEY_NAME, content: environmentLabels });
    }

    return output;
  },

  getProductEnvironmentLabels(
    product: CrossCountry.DetailedProduct,
    options: {
      customParser?: boolean;
    } = {}
  ) {
    if (!product.environment_label) {
      return null;
    }

    if (!options.customParser) {
      return product.environment_label.split(/\s*\?\s*/).join("<br />");
    }

    const labels: Record<string, string> = {};

    for (const part of product.environment_label.split("?")) {
      const [key, value] = part.split(":");

      if (key && value) {
        labels[key.trim()] = value.replace(/\s*\+\s*/, "<br />").trim();
      }
    }

    return labels;
  },

  getSortFieldDescriptorByContentful(sortBy: Editorials.SortProducts): EVA.Core.SortFieldDescriptor[] {
    switch (sortBy) {
      case "HIGHLIGHTS: New in":
        return [{ FieldName: "sellable_since", Direction: 1 }];
      case "HIGHLIGHTS: Top rated":
        return [{ FieldName: "review_average_score", Direction: 1 }];
      case "PRICE: High to low":
        return [{ FieldName: "display_price", Direction: 1 }];
      case "PRICE: Low to high":
        return [{ FieldName: "display_price", Direction: 0 }];
      case "NAME: A to Z":
        return [{ FieldName: "display_value", Direction: 0 }];
      case "NAME: Z to A":
        return [{ FieldName: "display_value", Direction: 1 }];
      case "none":
        return [];
      default:
        return [{ FieldName: "display_value", Direction: 0 }];
    }
  },

  /**
   * Binarily partition products list to avoid cycling on same family products (for which
   * only the first element should be taken). Elements must be sorted by parent id
   * @param prods The products list
   * @param lIdx The left-end index of the batch to work on
   * @param rIdx The right-end index of the batch to work on
   * @param end The last index of the batch to work on
   * @param trailId The parent id of the last element of the previous batch
   * @returns The list of products filtered by the first child of each family
   */
  extractFirstColorProductBinary(
    prods: CrossCountry.HierarchicalProduct[],
    lIdx: number,
    rIdx: number,
    end: number,
    trailId?: number
  ): CrossCountry.HierarchicalProduct[] {
    const ret: CrossCountry.HierarchicalProduct[] = [];
    // left part of the list products and parent ids
    const [lLastProduct] = [prods[rIdx - 1]];
    const lLastParentId = lLastProduct.logical_level_hierarchy?.[0]?.product_id;

    ret.push(...this._extractFirstColorProductChunk(prods, lIdx, rIdx, trailId));
    ret.push(...this._extractFirstColorProductChunk(prods, rIdx, end, lLastParentId));
    return ret;
  },

  _extractFirstColorProductChunk(
    prods: CrossCountry.HierarchicalProduct[],
    lIdx: number,
    rIdx: number,
    trailId?: number
  ) {
    const ret: CrossCountry.HierarchicalProduct[] = [];
    // left part of the list products and parent ids
    const [product, lastProduct] = [prods[lIdx], prods[rIdx - 1]];
    const [parentId, lastParentId] = [
      product?.logical_level_hierarchy?.[0]?.product_id,
      lastProduct.logical_level_hierarchy?.[0]?.product_id,
    ];

    // if first and last element of the batch have same parent id (and contain no standalones) ...
    // ... or they are the same product (batch size = 1):
    // then the first element should be taken ...
    // ... and the batch doesn't need to be further processed
    // (otherwise recursively split the batch until conditions are met)
    const sameFamily = parentId && lastParentId && parentId === lastParentId;
    const sameProduct = product.product_id === lastProduct.product_id;
    const trailing = parentId && trailId && parentId === trailId;
    const standalone = sameProduct && !parentId;
    if ((sameFamily || sameProduct) && (!trailing || standalone)) {
      ret.push(product);
    } else if (lIdx < rIdx && !(sameFamily && trailing)) {
      const nextRIdx = Math.ceil(lIdx + (rIdx - lIdx) / 2);
      ret.push(...this.extractFirstColorProductBinary(prods, lIdx, nextRIdx, rIdx, trailId));
    }

    return ret;
  },
  hasValidDisplayPrice(product: any): boolean {
    return typeof product?.display_price === "number";
  },
  roundToNearestQuarter(num: number): number {
    return Math.round(num * 4) / 4;
  },
};

export default productUtils;
