import debounce from "lodash/debounce";
import { createContext, useContext, useEffect, useState } from "react";

type WindowSize = {
  width?: number;
  height?: number;
};

type Breakpoint = "mobile" | "desktop" | undefined;

const Context = createContext<{
  breakpoint: Breakpoint;
  windowSize: WindowSize;
}>({ breakpoint: undefined, windowSize: { width: undefined, height: undefined } });

export default function BreakpointProvider(props: { children: React.ReactNode }) {
  const [windowSize, setWindowSize] = useState<WindowSize>({ height: undefined, width: undefined });
  const breakpoint = windowSize.width ? (windowSize.width >= 900 ? "desktop" : "mobile") : undefined;

  useEffect(() => {
    let mounted = true;
    const handleResize = debounce((entries) => {
      if (entries.length > 0) {
        requestAnimationFrame(() => {
          if (mounted) {
            setWindowSize({
              width: window.innerWidth,
              height: window.innerHeight,
            });
          }
        });
      }
    }, 200);

    const observer = new ResizeObserver(handleResize);
    observer.observe(document.body);
    checkScrollbarWidth();

    return () => {
      mounted = false;
      observer.disconnect();
    };
  }, []);

  return <Context.Provider value={{ breakpoint, windowSize }}>{props.children}</Context.Provider>;
}

function checkScrollbarWidth() {
  // check scrollbar width to avoid reflow on modals opening
  if (["complete", "interactive"].includes(document.readyState)) {
    applyScrollbarWidth();
  } else {
    let completedInterval = setInterval(() => {
      if (["complete", "interactive"].includes(document.readyState)) {
        // The page is fully loaded.
        applyScrollbarWidth();
        clearInterval(completedInterval);
        // completedInterval = undefined;
      } else {
        console.info("waiting for document.readyState completed");
      }
    }, 500);
  }
}

export function applyScrollbarWidth() {
  const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
  if (scrollBarWidth > 0) {
    document.body.style.setProperty("--scrollbar-width", scrollBarWidth + "px");
  } else {
    document.body.style.removeProperty("--scrollbar-width");
  }
}

export function useBreakpoint() {
  const context = useContext(Context);

  if (!context) {
    throw new Error(`useBreakpoint must be used within a BreakpointProvider`);
  }

  return context.breakpoint;
}

export function useWindowSize() {
  const context = useContext(Context);

  if (!context) {
    throw new Error(`useBreakpoint must be used within a BreakpointProvider`);
  }

  return context.windowSize;
}
